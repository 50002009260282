.main_func {
    .svg_tree {
        opacity: 0;
        &.active {
            opacity: 0;
        }
    }
    display: flex;
    // @media all and (max-width: 1880px) {
    //     align-items: flex-end;
    // }
    @media all and (max-width: 1800px) {
        flex-direction: column-reverse;
    }
    &__l {
        &--tit {
            display: none;
            @media all and (max-width: 1023px) {
                display: block;
                margin-bottom: 40px;
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                text-transform: uppercase;
                color: #957B61;
                text-align: center;
            }
        }
        flex: 0 0 vw(503);
        background: #fff;
        padding-top: vw(45);
        padding-bottom: vw(45);
        padding-left: vw(80);
        padding-right: vw(80);
        @media all and (max-width: 1800px) {
            flex: unset;
            width: 100%;
        }
        @media all and (max-width: 1023px) {
            padding: 60px 24px;
        }
        &--title {
            font-family: 'gml', serif;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            text-align: center;
            color: #000000;
            margin-bottom: 20px;
            @media all and (max-width: 1023px) {
                margin-bottom: 12px;
                font-size: 14px;
                font-family: 'gm' ,serif;
                font-weight: normal;
            }
            sup {
                top: -5px;
                font-size: 11px;
            }
        }
        &--option {
            margin-bottom: 40px;
            @media all and (max-width: 1023px) {
                margin-bottom: 10px;
            }
        }
        &--blocks {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &--block {
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            color: #000000;
            width: max-content;
            cursor: pointer;
            margin-bottom: 15px;
            transition: all .3s ease-out;
            @media all and (max-width: 1800px) {
                width: 33%;
            }
            @media all and (max-width: 1023px) {
                font-size: 14px;
                font-family: 'gm', serif;
                font-weight: normal;
            }
            span {
                background-image: linear-gradient(#957B61, #957B61);
                background-position: 0% 100%;
                background-repeat: no-repeat;
                background-size: 0% 1px;
                transition: background-size .3s;
                padding-bottom: 4px;
                display: inline-block;
            }
            &.active {
                span {
                    background-image: linear-gradient(#957B61, #957B61);
                    color: #957B61;
                    background-size: 100% 1px;
                }
            }
            @media all and (min-width: 1023px) {
                &:hover { 
                    span {
                        background-image: linear-gradient(#B69B80, #B69B80);
                        color: #B69B80;
                        background-size: 100% 1px;
                    }
                }
            }
        }
        .irs--flat .irs-line {
            height: 2px;
            background: #957B61;
        }
        .irs--flat .irs-bar {
            height: 2px;
            background: #c4c4c4;
        }
        .irs--flat .irs-handle > i, .irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
            display: none;
        }
        .irs--flat .irs-from, .irs--flat .irs-to {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
            background: #fff;
            z-index: 3;
            top: 5px;
            cursor: pointer;
        }
        .irs--flat .irs-min, .irs--flat .irs-max {
            display: none;
        }
        .irs--flat .irs-single {
            min-width: 40px;
            height: 40px;
            border-radius: 20px;
            border: 2px solid #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
            background: #fff;
            z-index: 3;
            top: 5px;
            cursor: pointer;
        }
        &--btn {
            display: block;
            text-decoration: none;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            text-transform: uppercase;
            color: #957B61;
            text-align: center;
            margin-top: 7px;
            span {
                display: inline-block;
                background-image: linear-gradient(currentColor, currentColor);
                background-position: 0% 100%;
                background-repeat: no-repeat;
                background-size: 0% 1px;
                transition: background-size .3s;
                padding-bottom: 3px;
            }
            &:hover {
                span {
                    background-size: 100% 1px;
                }
            }
            @media all and (max-width: 1023px) {
                margin-top: 40px;
            }
            @media all and (max-width: 680px) {
                width: 100%;
                margin-right: 0;
                font-size: 14px;
                margin-bottom: 12px;
            }
        }
    }
    &__r {
        width: 100%;
        position: relative;
        font-size: 0;
        @media all and (max-width: 1023px) {
            display: none;
        }
        svg {
            position: absolute;
            top: 0px;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: all .3s ease-in-out !important;
            z-index: 20;
            @media all and (max-width: 1880px) {
                top: 0;
            }
        }
        path {
            cursor: pointer;
            transition: all .3s ease-in-out !important;
        }
        img {
            width: 100%;
            height: 100%;
            @media all and (max-width: 1880px) {
                height: auto;
            }
        }
        .show3, .show2, .show1 {
            pointer-events: none;
            display: block !important;
            visibility: hidden;
            &.active {
                visibility: visible;
                opacity: 1;
                pointer-events: all;
                cursor: pointer;
                &:hover {
                   fill: #B69B80 
                }
            }
        }
    }
}

.main_page .main_func__l--block[data-id="6"] {
    order: 3;
}