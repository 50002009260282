.buy_tabs {
    padding: 0 80px;
    margin-top: 120px;
    @media all and (max-width: 1023px) {
        .box-wrap {
            height: auto !important;
        }
        .rast {
            display: none;
        }
    }
    @media all and (max-width: 768px) {
        margin-top: 60px;
        padding: 0 24px;
    }
    &__title {
        font-family: 'gml', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 56px;
        text-transform: uppercase;
        color: #957B61;
        margin-bottom: 80px;   
        @media all and (max-width: 768px) {
            font-size: 24px;
            margin-bottom: 40px;
        }   
    }
    .tabs {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        li {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            cursor: pointer;
            text-transform: uppercase;
            color: #FFFFFF;
            width: 25%;
            transition: all .3s ease-out;
            @media all and (max-width: 768px) {
                font-size: 14px;
                line-height: 22px;  
            }
            span {
                background-image: linear-gradient(#957B61, #957B61);
                background-position: 0% 100%;
                background-repeat: no-repeat;
                background-size: 0% 2px;
                transition: background-size .3s;
                padding-bottom: 8px;
                display: inline-block;
            }
            &.current {
                color: #957B61;
                span {
                    background-size: 100% 2px;
                    background-image: linear-gradient(#957B61, #957B61);
                }
            }
            &:hover {
                color: #B69B80;
                span {
                    background-size: 100% 2px;
                    background-image: linear-gradient(#B69B80, #B69B80);
                }
            }
            &:not(:last-child) {
                border-right: 1px solid #fff;
            }
            @media all and (max-width: 1023px) {
                width: 33%;
            }
        }
    }
    .box {
        padding-top: 80px;
        display: none;
        @media all and (max-width: 860px) {
            padding-top: 60px;
        }
        &.visible {
            display: block;
        }
        &__title {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 48px;
            color: #FFFFFF;
            margin-bottom: 60px;
            @media all and (max-width: 860px) {
                margin-bottom: 40px;
                font-size: 24px;
            }
        }
        &__blocks {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                margin-bottom: 100px;
            }
        }
        &__block {
            text-decoration: none;
            width: calc(33% - 20px);
            margin-bottom: 10px;
            display: block;
            @media all and (max-width: 1540px) {
                width: calc(50% - 20px);
                margin-bottom: 20px;
            }
            @media all and (max-width: 860px) {
                width: 100%;
            }
            &--img {
                font-size: 0;
                img {
                    width: 100%;
                }
            }
            &--info {
                background: #fff;
                padding: 40px;
                @media all and (max-width: 1540px) {
                    padding: 40px 16px;
                }
            }
            &--title {
                font-family: 'gml', serif;
                font-style: normal;
                font-weight: 300;
                font-size: 24px;
                line-height: 32px;
                color: #000000;
                margin-bottom: 20px;
                display: block;
                @media all and (max-width: 860px) {
                    height: auto !important;
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 40px;
                    font-family: 'gm', serif;
                    font-weight: normal;
                }
            }
            &--btns {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media all and (max-width: 980px) {
                    flex-direction: column-reverse;
                    align-items: flex-start;
                }
            }
            &--date {
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                color: #000000;
                opacity: 0.5;
                padding-right: 5px;
                @media all and (max-width: 980px) {
                    margin-top: 8px;
                }
                @media all and (max-width: 860px) {
                    font-size: 14px;
                }
            }
            &--more {
                font-family: 'gmm', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                text-transform: uppercase;
                color: #957B61;
                flex: 0 0 120px;
                background-image: linear-gradient(currentColor, currentColor);
                background-position: 0% 100%;
                background-repeat: no-repeat;
                background-size: 0% 1px;
                transition: background-size .3s;
                padding-bottom: 3px;
                &:hover {
                    background-size: 100% 1px;
                }
                @media all and (max-width: 980px) {
                    flex: none;
                }
                @media all and (max-width: 860px) {
                    font-size: 14px;
                    font-family: 'gmm', serif;
                    font-weight: 500;
                }
            }
        }
    }
}
.block_redf {
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1600px) {
        align-items: center;
    }
    @media all and (max-width: 1100px) {
        align-items: flex-start;
        flex-direction: column;
    }
    .box__title {
        margin-bottom: 20px;
    }
    &__l {
        padding-right: 40px;
        width: 50%;
        @media all and (max-width: 1100px) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 40px;
        }
        &--subtitle {
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            margin-bottom: 60px;
            @media all and (max-width: 768px) {
                font-size: 14px;
                margin-bottom: 40px;
            }
        }
        &--text {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;
            @media all and (max-width: 768px) { 
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
    &__r {
        width: 50%;
        font-size: 0;
        img {
            width: 100%;
        }
        @media all and (max-width: 1100px) {
            width: 100%;
        }
        @media all and (max-width: 768px) {
            margin-left: -24px;
            margin-right: -24px;
            img {
                width: calc(100% + 48px);
                max-width: unset;
            }
        }
    }
}
.razs {
    &__subtitle {
        font-family: 'gm', serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #FFFFFF;
        margin-bottom: 40px;
    }
    &__blocks {
        display: flex;
        flex-wrap: wrap;
    }
    &__block {
        width: 25%;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;
        @media all and (max-width: 1670px) {
            width: 33%;
        }
        @media all and (max-width: 1220px) {
            width: 50%;
        }
        > div {
            width: max-content;
            margin-left: auto;
            margin-right: auto;
            max-width: 335px;
            width: 100%;
        }
        &:first-child div {
            margin-left: 0;
        }
        &:first-child {
            padding-left: 0;
        }
        &:not(:last-child) {
            border-right: 1px solid #fff;
        }
        &--title {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin-bottom: 40px;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                font-family: 'gm', serif;
                margin-bottom: 40px;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 26px;
                color: #FFFFFF;
            }
        }
    }
}
.calc {
    display: flex;
    @media all and (max-width: 1180px) {
        flex-direction: column;
    }
    @media all and (max-width: 768px) {
        margin-bottom: 80px;
    }
    &__l {
        margin-right: 100px;
        flex: 0 0 652px;
        @media all and (max-width: 1600px) {
            margin-right: 50px;
            flex: none;
            width: calc(50% - 50px);
        }
        @media all and (max-width: 1180px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 40px;
        }
        &--text {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;
            margin-bottom: 50px;
            @media all and (max-width: 860px) {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 40px;
            }
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &--in {
            @media all and (max-width: 860px) {
                overflow: hidden;
                overflow-x: scroll;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
        @media all and (max-width: 860px) {
            flex-wrap: nowrap;
            width: max-content;
        }
        &__block {
            width: calc(20% - 10px);
            margin-bottom: 20px;
            text-align: center;
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            text-transform: uppercase;
            color: #FFFFFF;
            padding: 10px 4px;
            border: 1px solid #957B61;
            transition: all .3s ease-out;
            cursor: pointer;
            &:hover {
                background: #B69B80;
            }
            &.active {
                background: #957B61;
                &:hover {
                    background: #B69B80;
                }
            }
            @media all and (max-width: 860px) {
                padding-left: 20px;
                padding-right: 20px;
                background: none !important;
                width: max-content;
                border: 0;
                &:not(:last-child) {
                    border-right: 1px solid #fff;
                }
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
    &__r {
        width: 100%;
        @media all and (max-width: 1600px) {
            width: 50%;
        }
        @media all and (max-width: 1180px) {
            width: 100%;
        }
        form {
            > label {
                position: relative;
                div {
                    font-family: 'gm', serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    margin-bottom: 12px;
                    color: #FFFFFF;
                    @media all and (max-width: 860px) {
                        margin-bottom: 8px;
                        font-size: 14px;
                    }
                }
                input[type="number"] {
                    -moz-appearance: textfield;
                    border: 1px solid #FFFFFF;
                    text-align: center;
                    min-height: 80px;
                    font-family: 'gml', serif;
                    font-style: normal;
                    font-weight: 300;
                    background: transparent;
                    font-size: 24px;
                    color: #FFFFFF;
                    width: 100%;
                    border-radius: 0;
                    padding-left: 75px;
                    padding-right: 75px;
                    &::placeholder {
                        color: #FFFFFF;
                        opacity: 0.5;
                    }
                    &::-webkit-inner-spin-button {
                        display: none;
                        appearance: none;
                        -webkit-appearance: none;
                    }
                    &::-webkit-outer-spin-button {
                        display: none;
                        -webkit-appearance: none;
                        appearance: none;
                    }
                    @media all and (max-width: 860px) {
                        min-height: 46px;
                        font-size: 14px;
                        padding-left: 35px;
                        padding-right: 35px;
                    }
                }
                .jq-number__spin.minus {
                    margin: 0;
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    top: calc(50% + 16px);
                    margin-top: -12px;
                    left: 40px;
                    background: url("data:image/svg+xml,%3Csvg width='24' height='2' viewBox='0 0 24 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='2' fill='white'/%3E%3C/svg%3E%0A") 50% / 100% no-repeat;
                    border: 0;
                    font-size: 0;
                    cursor: pointer;
                    @media all and (max-width: 860px) { 
                        width: 12px;
                        top: calc(50% + 18px);
                        height: 12px;
                        left: 17px;
                    }
                }
                .jq-number__spin.plus {
                    margin: 0;
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    top: calc(50% + 16px);
                    margin-top: -12px;
                    right: 40px;
                    font-size: 0;
                    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 11V0H11V11H0V13H11V24H13V13H24V11H13Z' fill='white'/%3E%3C/svg%3E%0A") 50% / 100% no-repeat;
                    border: 0;
                    cursor: pointer;
                    @media all and (max-width: 860px) { 
                        width: 12px;
                        height: 12px;
                        top: calc(50% + 18px);
                        right: 17px;
                    }
                }
                &:first-child {
                    margin-bottom: 40px;
                    display: block;
                }
            }
            .sibl {
                display: flex;
                justify-content: space-between;
                margin-bottom: 40px;
                @media all and (max-width: 860px) {
                    flex-direction: column;
                    margin-bottom: 0px;
                }
                label {
                    display: block;
                    width: calc(50% - 20px);
                    position: relative;
                    @media all and (max-width: 860px) {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    div {
                        font-family: 'gm', serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        margin-bottom: 12px;
                        color: #FFFFFF;
                        @media all and (max-width: 860px) {
                            margin-bottom: 8px;
                            font-size: 14px;
                        }
                    }
                    input[type="number"] {
                        -moz-appearance: textfield;
                        border: 1px solid #FFFFFF;
                        text-align: center;
                        min-height: 80px;
                        font-family: 'gml', serif;
                        font-style: normal;
                        font-weight: 300;
                        background: transparent;
                        font-size: 24px;
                        color: #FFFFFF;
                        width: 100%;
                        border-radius: 0;
                        padding-left: 75px;
                        padding-right: 75px;
                        &::placeholder {
                            color: #FFFFFF;
                            opacity: 0.5;
                        }
                        &::-webkit-inner-spin-button {
                            display: none;
                            appearance: none;
                            -webkit-appearance: none;
                        }
                        &::-webkit-outer-spin-button {
                            display: none;
                            appearance: none;
                            -webkit-appearance: none;
                        }
                        @media all and (max-width: 860px) {
                            min-height: 46px;
                            font-size: 14px;
                            padding-left: 35px;
                            padding-right: 35px;
                        }
                    }
                    input[type="text"] {
                        border: 1px solid #FFFFFF;
                        text-align: center;
                        min-height: 80px;
                        font-family: 'gml', serif;
                        font-style: normal;
                        font-weight: 300;
                        background: transparent;
                        font-size: 24px;
                        color: #FFFFFF;
                        width: 100%;
                        border-radius: 0;
                        padding-left: 10px;
                        padding-right: 10px;
                        &::placeholder {
                            color: #FFFFFF;
                            opacity: 0.5;
                        }
                        @media all and (max-width: 860px) {
                            min-height: 46px;
                            font-size: 14px;
                        }
                    }
                    .jq-number__spin.minus {
                        margin: 0;
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        top: calc(50% + 16px);
                        margin-top: -18px;
                        left: 40px;
                        background: url("data:image/svg+xml,%3Csvg width='24' height='2' viewBox='0 0 24 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='2' fill='white'/%3E%3C/svg%3E%0A") 50% / 100% no-repeat;
                        border: 0;
                        font-size: 0;
                        cursor: pointer;
                        @media all and (max-width: 860px) { 
                            width: 12px;
                            top: calc(50% + 18px);
                            height: 12px;
                            left: 17px;
                        }
                    }
                    .jq-number__spin.plus {
                        margin: 0;
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        top: calc(50% + 16px);
                        margin-top: -18px;
                        right: 40px;
                        font-size: 0;
                        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 11V0H11V11H0V13H11V24H13V13H24V11H13Z' fill='white'/%3E%3C/svg%3E%0A") 50% / 100% no-repeat;
                        border: 0;
                        cursor: pointer;
                        @media all and (max-width: 860px) { 
                            width: 12px;
                            height: 12px;
                            top: calc(50% + 18px);
                            right: 17px;
                        }
                    }
                }
                &.text_frm {
                    @media all and (max-width: 860px) {
                        margin-top: 40px;
                        padding-top: 40px;
                        border-top: 1px solid #fff;
                        > label {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            div {
                                width: 50%;
                                margin-bottom: 0;
                            }
                            input[type="text"] {
                                width: 50%;
                                border: 0;
                                text-align: right;
                                padding-left: 5px;
                                padding-right: 0px;
                                &::placeholder {
                                    text-align: right;  
                                }
                            }
                        }
                    }
                }
            }
            .form_btn {
                border: 0;
                width: 100%;
                background: #957B61;
                font-family: 'gmm', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                text-transform: uppercase;
                color: #FFFFFF;
                text-align: center;
                padding: 27px 10px;
                transition: all .3s ease-out;
                &:hover {
                  background: #B69B80;
                }
                &:active {
                  background: #75604B;
                }
                @media all and (max-width: 860px) {
                    margin-top: 80px;
                    font-size: 14px;
                    padding: 14px 10px;
                }
            }
        }
    }
}