.main_red {
    &__info {
        padding: 120px 20px;
        @media all and (max-width: 680px) {
            padding: 80px 25px;
        }
        &--title {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 56px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 30px;
            color: #957B61;
            @media all and (max-width: 1200px) {
                font-size: 40px;
            }
            @media all and (max-width: 680px) {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 20px;
            }
        }
        &--text {
            font-family: 'gm';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #FFFFFF;
            a {
                color: #fff;
            }
            @media all and (max-width: 680px) {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
    &__img {
        overflow: hidden;
        max-height: 600px;
        font-size: 0;
        min-height: 120px;
        img {
            min-height: 120px;
            width: 100%;
            height: 100%;   
            object-fit: cover;
        }
    }
}
.footer {
    .main_red__info--text {
        font-size: 10px;
        line-height: normal;
        @media all and (max-width: 768px) {
            font-size: 8px;
        }
    }
}