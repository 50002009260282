h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}
body{
  // overflow-x: hidden;
  box-sizing: border-box;
  background: #000;
  display: flex;
  flex-direction: column;
  .footer {
    margin-top: auto;
  }
}
.page_wrapper {
  overflow: hidden;
}
*,::after,::before{
  box-sizing: inherit;
  outline: none;
}
html,
body {
  // height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}
.center_block{
  max-width: 1486px;
  width: 100%;
  padding-left: 23px;
  padding-right: 23px;
  margin-left: auto;
  margin-right: auto;
}
.clear{
  clear: both;
}
img {
  max-width: 100%;
  height: auto;
}
.buttons_btn {
  transition: all .3s ease-out;
  cursor: pointer;
  &:hover {
    background: #B69B80;
  }
  &:active {
    background: #75604B;
  }
}
// scroll
/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #957B61;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #957B61;
}
.room_page.ap_page.page_act {
  .footer {
    padding-top: 80px;
  }
}
//form
.form-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  display: -moz-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  &__info {
    font-family: "gm", serif;
    font-style: normal;
    font-weight: normal;
    font-size: vw(18);
    color: #000000;
    margin-top: vw(40);
    width: 100%;
    a {
      color: #957B61;
    }
  }
  &__title {
      font-family: 'gml', serif;
      font-style: normal;
      font-weight: 300;
      font-size: vw(48);
      color: #957B61;
      margin-bottom: vw(60);
  }
  &__subtitle {
      font-family: 'gm', serif;
      font-style: normal;
      font-weight: normal;
      font-size: vw(18);
      color: #000000;
      margin-bottom: vw(40);
  }
  &__form {
    width: 100%;
  }
  form,
  &__form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      label {
          width: calc(50% - 20px);
          div {
              font-family: 'gm', serif;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #000000;
              margin-bottom: 8px;
          }
          input[type="text"], input[type="tel"] {
              padding: 14px 20px;
              border: 1px solid #000000;
              font-family: 'gml', serif;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              border-radius: 0 !important;
              text-transform: uppercase;
              color: #000000;
              text-transform: uppercase;
              box-shadow: unset !important;
              width: 100%;
              &::placeholder {
                  opacity: 0.5;
              }
          }
      }
  }
  &__btns {
      max-width: vw(434);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: vw(60);
      margin-left: auto;
      margin-right: auto;
      input[type="submit"], .main_btn {
          cursor: pointer;
          max-width: vw(248);
          width: 100%;
          margin-right: vw(40);
          font-family: 'gmm', serif;
          font-style: normal;
          font-weight: 500;
          border-radius: 0 !important;
          font-size: vw(24);
          text-align: center;
          text-transform: uppercase;
          text-decoration: none;
          color: #FFFFFF;
          background: #957B61;
          text-align: center;
          padding: vw(17) vw(10);
          border: 0;
          transition: all .3s ease-out;
          cursor: pointer;
          line-height: normal;
          &:hover {
            background: #B69B80;
          }
          &:active {
            background: #75604B;
          }
      }
  }

  &.opened {
      opacity: 1;
      visibility: visible;
  }

  .form-wrapper-inner {
      max-width: 900px;
      width: 100%;
      background: #fff;
      position: relative;
      padding: vw(60) vw(60);
  }

  .title {
      font-size: 20px;
      padding-bottom: 20px;
      text-align: center;
  }

  .tpl-anketa-success-note {
      font-size: 20px;
      text-align: center;
  }

  .tpl-field {
      padding-bottom: 10px;

      input, textarea {
          outline: none;
          border: none;
          background: none;
          padding: 0;
          border: 1px solid #ccc;
          border-radius: 4px;
          width: 100%;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          height: 30px;
          padding: 0 15px;

          &:focus {
              border-color: #000;
          }
      }

      textarea {
          resize: none;
          padding-top: 5px;
          padding-bottom: 5px;
          height: 76px;
      }

      &.field-error {
          input, textarea {
              border-color: #f00;
          }
      }

      &.tpl-field-button {
          text-align: center;
          button {
              outline: none;
              border: none;
              background: none;
              padding: 10px 15px 11px;    
              cursor: pointer;
              background: #4279cb;
              color: #fff;
              border-radius: 5px;
              border-radius: 0 !important;
              font-weight: bold;
              font-size: 15px;
          }
      }
  }
  
  .field-required-mark {
      color: #f00;
  }
  
  .error-message {display: none;}
  
  .icon-close {
      position: absolute;
      right: vw(40);
      top: vw(40);
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.45469 0L0.000155474 1.45453L6.54551 7.99988L0 14.5454L1.45453 15.9999L8.00004 9.45441L14.5455 15.9998L16 14.5453L9.45457 7.99988L15.9998 1.45461L14.5453 7.85144e-05L8.00004 6.54535L1.45469 0Z' fill='%23957B61'/%3E%3C/svg%3E%0A") 50% no-repeat;
      width: 16px;
      height: 16px;
      cursor: pointer;
      &.btn {
          position: relative;
          right: unset;
          top: unset;
          width: auto;
          height: auto;
          cursor: pointer;
          max-width: vw(215);
          width: 100%;
          font-family: 'gmm', serif;
          font-style: normal;
          font-weight: 500;
          font-size: vw(24);
          text-align: center;
          text-transform: uppercase;
          color: #FFFFFF;
          background: #957B61;
          text-align: center;
          padding: vw(17) vw(10);
          transition: all .3s ease-out;
          cursor: pointer;
          &:hover {
            background: #B69B80;
          }
          &:active {
            background: #75604B;
          }
      }
  }
}
@media all and (max-width: 960px) {
  .form-wrapper.opened {
    overflow: auto;
  }
  .form-wrapper .form-wrapper-inner {    
    padding: 60px 25px;
    margin-top: 140px;
  }
  .form-wrapper .icon-close {
    top: -42px;
    right: 24px;
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.45469 0L0.000155474 1.45453L6.54551 7.99988L0 14.5454L1.45453 15.9999L8.00004 9.45441L14.5455 15.9998L16 14.5453L9.45457 7.99988L15.9998 1.45461L14.5453 7.85144e-05L8.00004 6.54535L1.45469 0Z' fill='%23fff'/%3E%3C/svg%3E%0A") 50% no-repeat;
  }
  .form-wrapper.new_form .icon-close {
    right: 40px !important;
    top: 40px !important;
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.45469 0L0.000155474 1.45453L6.54551 7.99988L0 14.5454L1.45453 15.9999L8.00004 9.45441L14.5455 15.9998L16 14.5453L9.45457 7.99988L15.9998 1.45461L14.5453 7.85144e-05L8.00004 6.54535L1.45469 0Z' fill='%23957B61'/%3E%3C/svg%3E%0A") 50% no-repeat !important;
  }
  .form-wrapper__title {
    margin-bottom: 20px;
    font-size: 24px;
    font-family: 'gm', serif;
    font-weight: normal;
  }
  .form-wrapper__subtitle {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 22px;
  }
  .form-wrapper form label {
    width: 100%;
    margin-bottom: 20px;
  }
  .form-wrapper__btns {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
  .form-wrapper__btns input[type="submit"], .form-wrapper .icon-close.btn, .form-wrapper__btns .main_btn {
    width: 100%;
    border-radius: 0 !important;
    margin: 0;
    margin-bottom: 20px;
    font-size: 14px;
    padding: 15px 10px;
    max-width: 100%;
  }
  .form-wrapper .icon-close.btn {
    margin-bottom: 0;
  }
  .cntr .form-wrapper__subtitle {
    text-align: left;
  }
}
//form end

@media all and (max-width: 768px) {
  .page_act {
    .footer { 
      padding-top: 0;
    }
  }
  .feedback2 {
    margin-top: 20px !important;
  }
}
// sup {
//   top: -16px;
//   font-size: 25px;
// }
input:focus {
  border: 1px solid #957B61 !important;
}
@media all and (max-width: 680px) {
  .main_page .site-panel-wrap {
    margin-top: -10px;
  }
}

.form-wrapper-inner .kladovie_sl .slick-dots {
  button {
    background: #957B61;
    box-shadow: none;
  }
}