.komc_in_white {
    .plc1 {
        &.active {
            fill: #BFDDEB;
        }
        &.more {
            fill: #92cfeb;
        }
    }
    .plc2 {
        &.active {
            fill: #FFDFBF;
        }
        &.more {
            fill: #facda1;
        }
    }
    .plc4 {
        &.active {
            fill: rgba(0,187,94,0.5);
        }
        &.more {
            fill: rgba(0,187,94,1);
        }
    }
    .block_info__car {
        &--img {
            width: 20px;
            height: 20px;
            background: #AAD2FF;
        }
    }
    .block_info__moto2 {
        display: flex;
        align-items: center;
        &--img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            background: #BFEED7;
        }
        @media all and (max-width: 480px) {
            margin-bottom: 10px;
        }
    }
    .main_head__center--backr {
        display: inline-block;
        margin-right: 40px;
        width: 20px;
        height: 48px;
        background: url("data:image/svg+xml,%3Csvg width='20' height='48' viewBox='0 0 20 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 0H16L0 24L16 48H20L4 24L20 0Z' fill='%23957B61'/%3E%3C/svg%3E%0A") 50% no-repeat;
    }
    .tit_in {
        display: flex;
        align-items: center;
    }
    @media all and (max-width: 680px) {
        .main_head__center--backr {
            margin-right: 20px;
            background-size: 100%;
            width: 10px;
            height: 23px;
        }
    }
}
@media all and (max-width: 960px) {
    .svg {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
