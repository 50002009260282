.parking_white2 {
    padding: 120px 80px;
    background: #fff;
    @media all and (max-width: 1320px) {
        padding: 60px 25px;
    }
    @media all and (max-width: 680px) {
        padding: 60px 10px;
    }
    .parking_white__l--btns {
        margin-top: 0;
    }
    &__in {
        display: flex;
        justify-content: space-between;
        position: relative;
        @media all and (max-width: 1320px) {
            flex-direction: column;
        }
    }
    &__l {
        flex: 0 0 420px;
        margin-right: 10px;
        @media all and (max-width: 1320px) {
            flex: none;
            margin-right: 0;
        } 
        &--title {
            font-family: 'gml', serif;
            font-style: normal;
            font-weight: 300;
            font-size: 48px;
            color: #957B61;
            margin-bottom: 80px;
            @media all and (max-width: 680px) {
                font-size: 24px;
                margin-bottom: 40px;
                font-family: 'gm', serif;
                font-weight: normal;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                cursor: pointer;
                &:before {
                    content: '';
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    top: 50%;
                    left: 0;
                    margin-top: -12px;
                    border: 1px solid #000;
                }
                &.active {
                    &:before {
                        border: 1px solid transparent;
                        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23 1H1V23H23V1ZM0 0V24H24V0H0Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.9999 8.42855L17.6476 7L10.8864 14.1426L6.35228 9.3527L5 10.7812L9.53411 15.5711L9.53392 15.5713L10.8862 16.9998L18.9999 8.42855Z' fill='%23957B61'/%3E%3C/svg%3E%0A") 50% no-repeat;
                    }
                }
                position: relative;
                padding-left: 34px;
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                color: #000000;
                margin-bottom: 20px;
                @media all and (max-width: 680px) {
                    font-size: 14px;
                }
            }
        }
        &--btns {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 60px;
            @media all and (max-width: 680px) {
                margin-top: 40px;
            }
        }
        &--link {
            display: inline-block;
            text-decoration: none;
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            text-transform: uppercase;
            color: #957B61;
            background-image: linear-gradient(currentColor, currentColor);
            background-position: 0% 100%;
            background-repeat: no-repeat;
            background-size: 0% 1px;
            transition: background-size .3s;
            padding-bottom: 3px;
            margin-bottom: 20px;
            &:hover {
                background-size: 100% 1px;
            }
            @media all and (max-width: 680px) {
                margin-bottom: 12px;
                font-size: 14px;
            }
        }
    }
    &__r {
        margin-top: -135px;
        svg {
            width: 100%;
            height: auto;
        }
        @media all and (max-width: 1320px) {
            margin-top: 0;
        }
        @media all and (max-width: 1024px) {
            margin-bottom: 40px;
        }
    }
    .plc1 {
        transition: all .3s ease-out;
        fill: #E6E6E6;
        cursor: pointer;
        pointer-events: none;
        // &.active {
        //     pointer-events: all;
        // }
    }
    .plc2 {
        transition: all .3s ease-out;
        fill: #fff;
        cursor: pointer;
        pointer-events: none;
        &.active {
            fill: #AC966D;
            pointer-events: all;
        }
        &.more {
            fill: #744F3C;
        }
        &:hover {
            fill: #744F3C;
        }
    }
    .plc3 {
        transition: all .3s ease-out;
        fill: #fff;
        cursor: pointer;
        pointer-events: none;
        &.active {
            fill: #F9E4E4;
            pointer-events: all;
        }
    }
    .plc4 {
        transition: all .3s ease-out;
        fill: #AC966D;
        cursor: pointer;
        pointer-events: all;
        &.active {
            fill: #AC966D;
            pointer-events: all;
        }
        &.more {
            fill: #744F3C;
        }
        &:hover {
            fill: #744F3C;
        }
    }
    .block_info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 60px;
        @media all and (max-width: 1320px) {
            margin-top: 0;
            margin-bottom: 10px;
        }
        @media all and (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
        &__car {
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-right: 20px;
            @media all and (max-width: 480px) {
                margin-bottom: 10px;
                margin-left: 0;
                margin-right: 0;
            }
            &--img {
                margin-right: 10px;
                display: flex;
                align-items: center;
            }
            &--text {
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
            }
        }
        &__moto {
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-right: 20px;
            @media all and (max-width: 480px) {
                margin-bottom: 10px;
                margin-left: 0;
                margin-right: 0;
            }
            &--img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                background: #AC966D;
            }
            &--text {
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
            }
        }
        &__sell {
            @media all and (max-width: 480px) {
                margin-bottom: 10px;
                margin-left: 0;
                margin-right: 0;
            }
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-right: 20px;
            &--img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                background: #E6E6E6;
            }
            &--text {
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
            }
        }
    }
    
        .hover_bl__block {
            &--link {
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
                padding: 27px 10px;
                background: #957B61;
                text-decoration: none;
                display: block;
                max-width: 197px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                transition: all .3s ease-out;
                &:hover {
                    background: #B69B80;
                }
            }
            &--title {
                font-size: 18px;
                font-weight: normal;
                font-family: 'gm', serif;
            }
            &--text {
                font-size: 24px;
            }
            &:nth-child(2) {
                max-width: 409px;
                width: 100%;
            }
        }
        .hover_bl {
            padding: 50px 60px;
            background: rgba(0,0,0,.9);
            &.hidden:not(.active) {
                display: none;
            }
            @media all and (max-width: 1320px) {
                padding: 20px;
                bottom: -5%;
            }
            @media all and (max-width: 1130px) {
                align-items: center;
                flex-wrap :wrap;
                .hover_bl__block:not(:last-child) {
                    border: 0;
                    width: 50% !important;
                    max-width: 50%;
                    margin-bottom: 5px;
                }
                .hover_bl__block {
                    width: 50% !important;
                }
            }
            @media all and (max-width: 960px) {
                .hover_bl__block--title {
                    font-size: 13px;
                    line-height: normal;
                }
                .hover_bl__block--text {
                    font-size: 15px;
                }
                .hover_bl__block--link {
                    font-size: 14px;
                    padding: 12px 10px;
                }
            }
            @media all and (max-width: 420px) {
                flex-direction: column;
                padding: 10px;
                .hover_bl__block, .hover_bl__block:not(:last-child) {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    .zoom_button {
        align-items: center;
        justify-content: center;
        display: none;
        @media all and (max-width: 1023px) {
            display: flex;
            margin-bottom: 20px;
            margin-top: 10px;
            &__in {
                font-family: 'gmm', serif;
                width: 25px;
                height: 25px;
                margin-left: 10px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #957B61;
                color: #957B61;
                font-size: 25px;
            }
            &__out {
                font-family: 'gmm', serif;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                margin-right: 10px;
                border: 2px solid #957B61;
                color: #957B61;
                font-size: 25px;
            }
        }
    }
    
    // 
}