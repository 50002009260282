.dinamic_main {
    padding: 0 80px;
    padding-top: 120px;
    @media all and (max-width: 960px) {
        padding-top: 60px;
        padding-left: 24px;
        padding-right: 24px;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 80px;
        @media all and (max-width: 1040px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
        }
    }
    &__h1 {
        font-family: 'gml', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 56px;
        text-transform: uppercase;
        color: #957B61;
        @media all and (max-width: 1040px) {
            margin-bottom: 60px;
        }
        @media all and (max-width: 768px) {
            font-size: 24px;
            font-weight: normal;
            font-family: 'gm', serif;
        }
    }
    &__bottom {
        &--img {
            opacity: 1;
            transition: all .3s ease-out;
            width: 100%;
            position: relative;
            font-size: 0;
            z-index: -1;
            div {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                opacity: 0.8;
                position: absolute;
                z-index: 2;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                padding-bottom: 60px;
                padding-top: 20px;
                padding-left: 10px;
                padding-right: 10px;
                text-align: center;
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 32px;
                color: #FFFFFF;
            }
            img {
                @media all and (max-width: 1040px) {
                    min-height: 327px;
                    max-height: 327px;
                    width: 100%;
                    height: 100%;   
                    object-fit: cover;
                }
            }
        }
        &--video { 
            transition: all .3s ease-out;
            display: none;
            opacity: 0;
            iframe {
                width: 100% !important;  
                height: 800px !important;
                @media all and (max-width: 1040px) {
                    height: 327px !important;
                }
            }
        }
        &.active {
            .dinamic_main__bottom--video {
                display: block;
                opacity: 1;
            }
            .dinamic_main__bottom--img {
                display: none;
                opacity: 0;
            }
        }
    }
}
.switch__item { 
    display: flex;
    align-items: center;
    div {
        margin-left: 20px;
        margin-right: 20px;
        font-family: 'gm', serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #FFFFFF;
        @media all and (max-width: 1040px) {
            &:nth-child(1) {
                margin-left: 0;
            }
        }
        @media all and (max-width: 768px) {
            font-size: 14px;
            margin-left: 8px;
            margin-right: 8px;
            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }
}
.switch {
    position: relative;
    display: inline-block;
    width: 78px;
    z-index: 1;
    height: 40px;
    @media all and (max-width: 768px) {
        width: 40px;
        height: 22px;
    }
  }
  
  .switch input {display:none;}
  
  .slider:before {
    position: absolute;
    content: "";
    height: 34px;
    width: 34px;
    left: 3px;
    bottom: 2.5px;
    background-color: #957B61;
    -webkit-transition: .4s;
    transition: .4s;
    @media all and (max-width: 768px) {
        width: 18px;
        height: 18px;
        left: 1px;
        bottom: 1.5px;
    }
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #fff; 
    -webkit-transition: .4s;
    transition: .4s;
    &:hover {
        &:before {
            background: #B69B80;
        }
    }
  }
  
  input:checked + .slider {

  }
  
  input:focus + .slider {

  }
  
  input:checked + .slider:before {
    transform: translateX(36px);
    @media all and (max-width: 768px) {
        transform: translateX(18px);
    }
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .dinamic_page {
      display: flex;
      flex-direction: column;
      .footer {
          margin-top: auto;
      }
  }