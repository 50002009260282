.header {
    position: relative;
    z-index: 20;
    .main_head__top--right {
        width: 100%;
    }
    &__in {
        .main_head__top--logo {
            margin-left: auto;
            margin-right: auto;
        }
    }
}