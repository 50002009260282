body {
    overflow-x: hidden;
}
.main_head {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    &__in {
        // overflow: hidden;
    }
    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;   
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 19.27%);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    @keyframes move {
        25% {
            opacity: 1;
        }
        33% {
            opacity: 1;
        }
        67% {
            opacity: 1;
        }
        100% {
            transform: translateY(55px);
            opacity: 0;
        }
    }
    @keyframes move2 {
        25% {
            opacity: 1;
        }
        33% {
            opacity: 1;
        }
        67% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateY(10px);
        }
    }
    @keyframes pulse {
        to {
            opacity: 1;
        }
    }
    &:after {
        position: absolute;
        content: '';
        bottom: 80px;
        left: 50%;
        width: 30px;
        height: 26px;
        z-index: 3;
        margin-left: -8px;
        background: url("data:image/svg+xml,%3Csvg width='26' height='16' viewBox='0 0 26 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8001 13.5386L-6.99382e-07 0.000120163L-5.91786e-07 2.46163L12.7998 15.9999L12.7999 15.9999L12.8001 16.0001L25.5999 2.46184L25.5999 0.000337435L12.8001 13.5386Z' fill='white'/%3E%3C/svg%3E%0A") 50% no-repeat;
        @media all and (min-width: 681px) {
            animation: move 3s ease-out 2s infinite;
        }
        @media all and (max-width: 680px) {
            bottom: 50px;
            animation: move2 3s ease-out 2s infinite;
        }
    }
    * {
        z-index: 2;
    }
    &__top {
        z-index: 99;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 80px;position: relative;
        top: 0;
        @media all and (max-width: 680px) {
            padding: 0 25px;
        }
        &--brg {
            display: block;
            @media all and (max-width: 680px) {
                max-width: 40px;
                width: 100%;
            }
        }
        &--logo {
            display: flex;
            align-items: center;
            @media all and (max-width: 680px) {
                max-width: 80px;
                width: 100%;
            }
        }
        &--right {
            display: flex;
            justify-content: space-between;
            width: 55%;
            position: relative;
            margin-top: 31px;
            @media all and (max-width: 680px) {
                margin-top: 24px;
                width: 56%; 
            }
            @media all and (max-width: 375px) {
                width: 65%;
            }
        }
        &--tel {
            display: flex;
            align-items: center;
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            text-transform: uppercase;
            color: #FFFFFF;
            margin: 0 20px;
            margin-right: 50px;
            text-decoration: none;
            font-size: 15px;
            @media all and (max-width: 1400px) {
                display: none;
            }
            @media all and (min-width: 1600px) {
                font-size: 18px;
            }
        }
        &--btn {
            margin-left: auto;
            margin-right: 0;
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            text-decoration: none;
            display: flex;
            align-items: center;
            background-position: 0% 95%;
            background-repeat: no-repeat;
            // background-size: 0% 2px;
            transition: background-size .3s;
            &:hover {
                background-size: 100% 2px;
            }
            @media all and (max-width: 1400px) {
                display: none;
            }
            @media all and (min-width: 1600px) {
                font-size: 18px;
            }
        }
        &--logo + &--btn {
            background-image: linear-gradient(#957B61, #957B61);
            padding: 15px 20px;
        }
        &.fixed {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background: #000;
            z-index: 30;
            .main_head__top--brg {
                img {
                    display: none;
                }
            }
            .main_head__top--right {
                margin-top: 0;
                padding: 30px 0px;
                @media all and (max-width: 680px) {
                    padding: 24px 0;
                }
            }
            .site-panel-wrap .burger-wrap.opened .burger-body {
                transform: translate(calc(-100% + 120px), -60px);
                @media all and (max-width: 680px) {
                    transform: translate(calc(-100% + 60px), -40px);
                }
            }
            .site-panel-wrap .burger-wrap .burger-body {
                transform: translate(150%, -60px);
                @media all and (max-width: 680px) {
                    transform: translate(150%, -40px);
                }
            }
        }
    }
    &__center {
        margin-top: 135px;
        margin-bottom: auto;
        z-index: 1;
        padding-left: 25px;
        padding-right: 25px;
        position: relative;
        &--logo {
            text-align: center;
            display: block;
        }
        &--info {
            display: none;
            margin-top: 44px; 
            padding-bottom: 40px;
            @media all and (max-width: 1399px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            @media all and (max-width: 960px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                a {
                    @media all and (max-width: 680px) {
                        font-size: 14px;
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                        margin-bottom: 10px !important;
                        // text-align: left;
                        // margin-right: 10px;
                        // &:first-child {
                        //     width: 130px;
                        // }
                    }
                }
            }
        }
        &--btn {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            text-decoration: none;
            margin: 0 20px;
        }
        &--tel {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin: 0 20px;
            text-decoration: none;
            @media all and (max-width: 680px) {
                font-size: 14px;
                margin: 0;
                margin-left: 10px;
                text-align: left;
            }
            @media all and (max-width: 370px) {
                margin-left: 0;
            }
        }
    }
}
.site-panel-wrap {
    z-index: 10;
    position: absolute;    top: 50%;
    margin-top: -18px;
    right: 0;
    @media all and (max-width: 680px) {
        margin-top: -8px;
    }

    & .burger-wrap {
        width: 40px;
        height: 40px;
        @media all and (max-width: 680px) {
            width: 20px;
            height: 20px;
        }
        
        & .burger-btn {
        width: 40px;
        height: 40px;
        display: block;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media all and (max-width: 680px) {
            width: 20px;
            height: 20px;
        }
        
        span {
            margin-bottom: 5px;
            display: block;
            height: 2px;
            background: #fff;
            width: 20px;
        }
        }
        & .burger-close-btn {
            width: 16px;
            height: 16px;
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.0209961 15.0598L0.960923 15.9998L8.0104 8.95029L15.0599 15.9998L15.9999 15.0599L8.95032 8.01036L15.9998 0.960923L15.0598 0.0209961L8.0104 7.07043L0.961024 0.0210616L0.0210965 0.960989L7.07047 8.01036L0.0209961 15.0598Z' fill='black'/%3E%3C/svg%3E%0A");
            position: absolute;
            margin-left: auto;
            position: absolute;
            top: 45px;
            right: 80px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            cursor: pointer;
            @media all and (max-width: 680px) {
                position: absolute;
                top: 24px;
                right: 24px;
            }
        }
        
        &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s;
        }
        
        &.opened {
        .burger-body {
            -webkit-transform: translate(calc(-100% + 120px), -66px);
                -ms-transform: translate(calc(-100% + 120px), -66px);
                transform: translate(calc(-100% + 120px), -66px);
                @media all and (max-width: 680px) {
                    transform: translate(calc(-100% + 60px), -47px);
                }
        }
        
        &:before {
            visibility: visible;
            opacity: 1;
            z-index: 3;
            background: rgba(0,0,0,0.6);
        }
        }
        
        & .burger-body {
            -webkit-transform: translate(150%, -78px);
            -ms-transform: translate(150%, -78px);
                transform: translate(150%, -78px);
            transition: all .5s;
            width: 380px;
            background: #fff;
            height: 100vh;
            overflow-y: auto;
            position: relative;
            padding: 100px 40px;
            padding-bottom: 40px;
            display: flex;
            flex-direction: column;
            z-index: 10;
            align-items: flex-start;
            justify-content: space-between;
            @media all and (max-width: 680px) {
                transform: translate(150%, -47px);
                padding-top: 25px;
                width: 375px;
                padding-left: 24px;
                padding-right: 24px;
            }
            @media all and (max-width: 374px) {
                width: 320px;
            }
            &__logo {
                display: none;
                margin-bottom: 40px;
                @media all and (max-width: 680px) {
                    display: block;
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 80px;
                    width: 100%;
                    filter: invert(1);
                }
            }
            &__nav {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li {
                        overflow: hidden;
                        a {
                            display: block;
                            margin-bottom: 30px;
                            text-decoration: none;
                            font-family: 'gm', serif;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            text-transform: uppercase;
                            color: #000000;
                            transition: all .3s ease-out;
                            &:hover {
                                color: #957B61;
                                transform: scale(1.2) translate(24px, 0px);
                                //font-size: 20px;
                            }
                            @media all and (max-width: 680px) {
                                font-size: 14px;
                                margin-bottom: 14px;
                            }
                        }
                        &.has {
                            a {
                                margin-bottom: 17px;
                                @media all and (max-width: 680px) {
                                    margin-bottom: 14px;
                                }
                            }
                        }
                        .submenu {
                            margin-left: 15px;
                            margin-bottom: 15px;
                            @media all and (max-width: 680px) {
                                display: none;
                            }
                            li {
                                a {
                                    text-transform: unset;
                                }
                            }
                        }
                    }
                }
            }
            &__info {
                margin-top: auto;
                &--tel {
                    display: block;
                    text-decoration: none;
                    margin-bottom: 20px;
                    margin-top: 120px;
                    font-family: 'gm', serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    text-transform: uppercase;
                    color: #000000;
                    @media all and (max-width: 680px) {
                        margin-top: 22px;
                        font-size: 14px;
                    }
                }
                &--soc {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    a {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 30px;
                        margin-bottom: 5px;
                        @media all and (max-width: 680px) {
                            width: 24px;
                            height: 24px;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
}
.owf {
    overflow: hidden !important;
}
.main_page {
    .site-panel-wrap {
        top: 50%;
        margin-top: -18px;
    }
}