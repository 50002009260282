.notfound_page {
    padding-top: 120px;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: 1343px) {
        flex-direction: column;
    }
    @media all and (max-width: 960px) {
        padding-top: 60px;
        padding-left: 24px;
        padding-right: 24px;
    }
    &__l {
        padding-right: 92px;
        margin-right: 92px;
        border-right: 1px solid #fff;
        flex: 0 0 565px;
        @media all and (max-width: 1680px) {
            padding-right: 40px;
            margin-right: 40px;
        }
        @media all and (max-width: 1343px) {
            flex: none;
            margin-right: 0;
            padding-right: 0;
            border: 0;
            border-bottom: 1px solid #fff;
            margin-bottom: 60px;
            padding-bottom: 60px;
            width: 100%;
            max-width: 100%;
            text-align: center;
        }
        &--top {
            font-family: 'gml';
            font-style: normal;
            font-weight: 300;
            font-size: 288px;
            line-height: 288px;
            text-transform: uppercase;
            color: #957B61;
            @media all and (max-width: 680px) {
                font-size: 144px;
                line-height: 144px;
            }
        }
        &--bot {
            font-family: 'gm';
            font-style: normal;
            font-weight: normal;
            font-size: 40px;
            text-transform: uppercase;
            color: #957B61;
            text-align: center;
            margin-top: 20px;
            @media all and (max-width: 680px) {
                font-size: 24px;
                margin-top: 15px;
            }
        }
    }
    &__r {
        width: 100%;
        &--title {
            font-family: 'gm';
            font-style: normal;
            font-weight: normal;
            font-size: 48px;
            color: #FFFFFF;
            margin-bottom: 20px;
            @media all and (max-width: 680px) {
                font-size: 14px;
            }
        }
        &--text {
            font-family: 'gm';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;
            margin-bottom: 60px;
            a {
                text-decoration: none;
                color: #957B61;
                background-image: linear-gradient(#957B61, #957B61);
                background-position: 0% 95%;
                background-repeat: no-repeat;
                background-size: 0% 1px;
                transition: background-size .3s;
                &:hover {
                    background-size: 100% 1px;
                }
            }
            @media all and (max-width: 680px) {
                font-size: 14px;
                line-height: 22px;
            }
        }
        &--menu {
            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                li {
                    width: calc(33% - 50px);
                    @media all and (max-width: 1720px) {
                        width: 33%;
                    }
                    @media all and (max-width: 1540px) {
                        width: 50%;
                    }                   
                    a {
                        text-decoration: none;
                        font-family: 'gmm';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        color: #957B61;
                        display: inline-block;
                        text-transform: uppercase;
                        margin-bottom: 17px;
                        background-image: linear-gradient(#957B61, #957B61);
                        background-position: 0% 95%;
                        background-repeat: no-repeat;
                        background-size: 0% 1px;
                        padding-bottom: 3px;
                        transition: background-size .3s;
                        &:hover {
                            background-size: 100% 1px;
                        }
                        @media all and (max-width: 680px) {
                            font-size: 14px;
                        }
                    }
                    @media all and (max-width: 680px) {
                        width: 100%;
                        text-align: center;
                        &:last-child {
                            a {
                                margin-bottom: 0px;
                            }
                        }
                    } 
                }
            }
        }
    }
}