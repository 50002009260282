.ap_page {
    .main_dop {
        @media all and (max-width: 960px) {
            margin-bottom: 20px;
        }
    }
    .room_center {
        max-width: 1430px;
        align-items: flex-start;
        @media all and (max-width: 1220px) {
            flex-direction: column-reverse;
            align-items: center;
        }
    }
    .main_blocks__block--btns {
        max-width: 430px;
        width: 100%;
        @media all and (max-width: 1220px) {
            max-width: 100%;
        }
    }
    .main_blocks__block--btn {
        margin-bottom: 20px;
    }
    .room_blocks__block--img {
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    @media all and (max-width: 1220px) {
        .room_center__l {
            margin-top: 80px;
        }
        .room_center__l, .room_center__l--list {
            max-width: 100%;
            width: 100%;
        }
    }
    @media all and (max-width: 640px) {
        .room_center__l {
            margin-top: 0px;
        }
        .room_center__r {
            max-width: 249px;
            width: 100%;
        }
        .main_blocks__block--btns {
            flex-direction: column;
        }
        .main_blocks__block--btn:last-child {
            margin-bottom: 0;
        }
    }
    .room_center__r {
        max-width: 560px;
        width: 100%;
        .slick-prev {
            width: 20px;
            height: 40px;
            &:before {
                display: none !important;
            }
            background: url("data:image/svg+xml,%3Csvg width='20' height='48' viewBox='0 0 20 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 0H16L0 24L16 48H20L4 24L20 0Z' fill='white'/%3E%3C/svg%3E%0A") 50% no-repeat;
            left: -60px;
            top: 50%;
            margin-top: -20px;
            z-index: 10;
            @media all and (max-width: 780px) {
                width: 10px;
                height: 14px;
                margin-top: -7px;
                background: url("data:image/svg+xml,%3Csvg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.133301 6.89299L0.789566 6.27253L7.35372 0.0664062L9.46652 0.0664063L2.24596 6.89312L9.46638 13.7197L7.35358 13.7197L0.133301 6.89299Z' fill='white'/%3E%3C/svg%3E%0A") 50% no-repeat;
                left: -20px;
            }
        }
        .slick-next {
            width: 20px;
            height: 40px;
            &:before {
                display: none !important;
            }
            background: url("data:image/svg+xml,%3Csvg width='20' height='48' viewBox='0 0 20 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0H4L20 24L4 48H0L16 24L0 0Z' fill='white'/%3E%3C/svg%3E%0A") 50% no-repeat;
            right: -60px;
            top: 50%;
            margin-top: -20px;
            z-index: 10;
            @media all and (max-width: 780px) {
                width: 10px;
                height: 14px;
                margin-top: -7px;
                background: url("data:image/svg+xml,%3Csvg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.8667 6.89299L9.21043 6.27253L2.64628 0.0664062L0.533482 0.0664063L7.75404 6.89312L0.533618 13.7197L2.64642 13.7197L9.8667 6.89299Z' fill='white'/%3E%3C/svg%3E%0A") 50% no-repeat;
                right: -20px;
            }
        }
        .slick-dots {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: -35px;
            li {
                display: block;
                width: auto;
                height: auto;
                padding: 0;
                margin: 0;
                button {
                    width: 32px;
                    height: 2px;
                    margin-left: 6px !important;
                    margin-right: 6px !important;
                    padding: 0;
                    background: rgba(255,255,255, .6);
                    &:before {
                        display: none !important;
                    }
                    @media all and (max-width: 640px) {
                        width: 20px;
                    }
                }
                &.slick-active {
                    button {
                        background: #fff;
                    }
                }
            }
        }
    }
    .room_center {
        max-width: 100%;
        justify-content: space-between;
        align-items: center;
        &__l {
            width: 33%;
        }
        &__r {
            width: 33%;
            position: relative;
            z-index: 10;
        }
        &__rl {
            max-width: 33%;
            width: 100%;
            padding-left: 11%;
            @media all and (max-width: 1600px) {
                padding-left: 2%;
            }
            &--li {
                display: flex;
                text-align: left;
                text-decoration: none;
                align-items: center;
                text-transform: uppercase;
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                color: #FFFFFF;
                margin-bottom: 30px;
            }
            &--img {
                margin-right: 30px;
            }
            &--title {
                text-transform: uppercase;
                font-family: 'gm', serif;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                color: #FFFFFF;
            }
        }
    }
}
.ap_blocks {
    padding: 0 80px;
    margin-top: 120px;
    @media all and (max-width: 960px) {
        padding: 0px;
        margin-top: 40px;
    }
    .irs--flat .irs-line {
        height: 2px;
        background: #4E4E4E;
    }
    .irs--flat .irs-bar {
        height: 2px;
        background: #957B61;
    }
    .irs--flat .irs-handle > i, .irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
        display: none;
    }
    .irs--flat .irs-from, .irs--flat .irs-to {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'gmm', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #000000;
        background: #fff;
        z-index: 3;
        top: 5px;
        cursor: pointer;
    }
    .irs--flat .irs-min, .irs--flat .irs-max {
        display: none;
    }
    .irs--flat .irs-single {
        min-width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 2px solid #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        z-index: 3;
        top: 5px;
        background: #000000;
        border: 2px solid #957B61;
        cursor: pointer;
        @media all and (max-width: 960px) {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-top: 10px;
            min-width: 20px;
        }
    }
    &__title {
        font-family: 'gm', serif;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        color: #FFFFFF;
        margin-bottom: 40px;
        @media all and (max-width: 960px) {
            margin-bottom: 14px;
            font-size: 24px;
        }
    }
    .ap_blocks__title, .box__title {
        color: #957B61;
    }
    &__text {
        max-width: 860px;
        margin-bottom: 40px;
        width: 100%;
        font-family: 'gm', serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: #FFFFFF;
        @media all and (max-width: 960px) {
            font-size: 14px;
            line-height: 22px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        justify-content: space-between;
        &--block {
            margin-bottom: 20px;
            border: 1px solid #4E4E4E;
            width: calc(33% - 20px);
            padding: 57px 40px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            @media all and (max-width: 1240px) {
                width: calc(50% - 20px);
            }
            @media all and (max-width: 960px) {
                width: 100%;
                padding: 40px 30px;
            }
        }
        &--title {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            text-align: center;
            margin-bottom: 40px;
            color: #FFFFFF;
            @media all and (max-width: 960px) {
                margin-bottom: 20px;
                font-size: 14px;
            }
        }
        &--numb {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 48px;
            text-align: center;
            color: #957B61;
            margin-bottom: 30px;
            @media all and (max-width: 960px) {
                font-size: 24px;
                margin-bottom: 10px;
            }
        }
    }
}
.ap_tabs {
    padding: 0 80px;
    margin-bottom: 80px;
    @media all and (max-width: 960px) {
        padding: 0 0px;
        margin-bottom: 40px;
    }
    &__button {
        text-align: center;
        @media all and (max-width: 960px) {
            padding-left: 24px;
            padding-right: 24px;
        }
        a {
            display: block;
            text-decoration: none;
            max-width: 350px;
            width: 100%;
            padding: 27px 10px;
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            text-transform: uppercase;
            color: #FFFFFF;
            text-align: center;
            background: #957B61;
            margin-left: auto;
            margin-right: auto;
            transition: all .3s ease-out;
            &:hover {
                background: #B69B80;
            }
            @media all and (max-width: 960px) {
                font-size: 14px;
                padding: 15px 10px;
                max-width: 100%;

            }
        }
    }
    &__titles {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        &--block {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: #FFFFFF;
            width: 33%;
            &:not(:first-child) {
                text-align: center;
            }
            &:first-child {
                width: 100%;
                max-width: 580px;
            }
            &:last-child {
                max-width: 279px;
                width: 100%;
            }
        }
        @media all and (max-width: 1160px) {
            display: none;
        }
    }
    &__blocks {
        display: flex;
        background: #1A1A18;
        border: 1px solid #FFFFFF;      
        justify-content: space-between;
        padding-top: 14px;
        padding-bottom: 14px;
        margin-bottom: 20px;
        @media all and (max-width: 1160px) {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: unset;
            padding: 20px;
        }
        &--block {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #FFFFFF;
            width: 33%;
            display: flex;
            align-items: center;
            @media all and (max-width: 1160px) {
                justify-content: space-between;
                width: 100%;
                flex: none;
                align-items: unset;
                font-size: 14px;
            }
            &:not(:last-child) {
                border-right: 1px solid #C4C4C4;
                @media all and (max-width: 1160px) {
                    border: 0;
                }
            }
            &:not(:first-child) {
                text-align: center;
                justify-content: center;
                @media all and (max-width: 1160px) {
                    justify-content: space-between;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    border-top: 1px solid #C4C4C4;
                }
            }
            &:first-child {
                width: 100%;
                max-width: 580px;
                padding-left: 40px;
                @media all and (max-width: 1160px) {
                    max-width: 100%;
                    flex: none;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0;
                    padding-bottom: 20px;
                }
            }
            &:last-child {
                max-width: 279px;
                width: 100%;
                @media all and (max-width: 1160px) {
                    max-width: 100%;
                }
            }
        }
        &--span {
            display: none;
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #FFFFFF;
            @media all and (max-width: 1160px) {
                display: block;
            }
        }
        &--img {
            max-width: 128px;
            width: 100%;
            margin-right: 40px;
            margin-top: 6px;
            margin-bottom: 6px;
            @media all and (max-width: 1160px) {
                margin: 0;
                max-width: 82px;
                margin-right: 20px;
            }
        }
        &--title {
            @media all and (max-width: 1160px) {
                font-size: 20px;
            }
        }
    }
} 
.title_new {
    margin-bottom: 80px;
    font-weight: normal;
    font-family: 'gm', serif;
    @media all and (max-width: 960px) {
        margin-bottom: 30px;
    }
}
.room_page.ap_page.page_act {
    .main_head__center--rr {
        width: 30% !important;
        @media all and (max-width: 1500px) and (min-width: 1400px) {
            width: auto !important;
        }
    }
    .main_head__center {
        padding-bottom: 0;
    }
    .hover_bl {
        &.hidden:not(.active) {
            display: none;
        }
        position: relative;
        opacity: 1;
        visibility: visible;
        bottom: 0;
        z-index: 11;
        margin-top: 40px;
        align-items: unset;
        background: rgba(0,0,0,.9);
        &__block {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
    .new_tabs {
        .tabs {
            justify-content: center;
            li {
                width: 50%;
            }
        }
        .rast {
            display: block !important;
        }
        .box {
            display: none;
            align-items: center;
            justify-content: center;
            margin-top: 100px;
            @media all and (max-width: 960px) {
                margin-top: 60px;
            }
            img {
                margin-left: auto;
                margin-right: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.visible {
                display: block;
            }
        }
    }
    .rooms_tbs {
        display: none;
    }
    .buy_tabs {
        display: block !important;
    }
    .pagingInfo {
        margin-bottom: 80px;
    }
    @media all and (max-width: 1600px) {
        .slick-prev, .slick-next {
            display: none !important;
        }
        .room_center__l {
            margin-right: 20px;
        }
    }
    @media all and (max-width: 1220px) {
        .main_head__center--rr{
            width: 100% !important;
        }
        .room_center__r {
            order: 0;
        }
        .room_center__l {
            order: 2;
            margin-right: 0;
        }
        .room_center__rl {
            order: 3; 
            padding-left: 0;
            margin-top: 60px;
            max-width: 220px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        .room_center__rl--img {
            flex: 0 0 30px;
        }
        .room_center__rl--title {
            font-size: 14px;
        }
        .room_center {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    @media all and (max-width: 960px) {
        .main_head {
            background: #000 !important;
        }  
    }
    @media all and (max-width: 860px) {
        .hover_bl__block {
            width: 50%;
            margin-bottom: 20px;
            border: 0;
            text-align: left;
            border-left: 1px solid #fff;
            align-items: flex-start;
            height: max-content;
            padding-left: 20px;
            justify-content: flex-start;
            padding-right: 20px;
        }
        .hover_bl__block--title {
            font-size: 14px;
        }
        .hover_bl {
            flex-wrap: wrap;
            padding: 0;
            &.hidden:not(.active) {
                display: none;
            }
        }
    }
    @media all and (max-width: 768px) {
        .main_head__center--sort {
            width: 66px;
        }
        .buy_tabs .tabs li {
            font-size: 12px;
        }
    }
    @media all and (max-width: 460px) {
        .hover_bl__block {
            width: 100%;
        }
    }
}
.params_blocks .irs--flat .irs-from, .params_blocks .irs--flat .irs-to {
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}
.feedback {
    cursor: pointer;
}
.new_form {
    .form-wrapper-inner {
        max-width: 100%;
        height: 100%;
        @media all and (max-width: 960px) {
            margin-top: 0;
        }
    }
}
.new_form {
    iframe {
        width: 100%;
        height: 100%;
    }
}
@media all and (max-width: 768px) {
    .room_page.ap_page.page_act .main_head__center--sort svg {
        height: auto;
    }
}