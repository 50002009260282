.main_rmap {
    &__info {
        padding-top: 120px;
        padding-bottom: 80px;
        @media all and (max-width: 680px) {
            padding-top: 80px;
            padding-bottom: 40px;
        }
        &--title {
            font-family: 'gm';
            font-style: normal;
            font-weight: normal;
            font-size: 56px;
            text-transform: uppercase;
            color: #957B61;
            margin-bottom: 60px;
            text-align: center;
            @media all and (max-width: 680px) {
                font-size: 24px;
                margin-bottom: 40px;
            }
        }
        &--blocks {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &--block {
            width: calc(33% - 20px);
            margin-bottom: 40px;
            @media all and (max-width: 960px) {
                width: calc(50% - 30px);
            }
        }
        &--tit {
            font-family: 'gm';
            font-style: normal;
            font-weight: normal;
            font-size: 48px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 12px;
            @media all and (max-width: 680px) {
                font-size: 24px;
                margin-bottom: 4px;
            }
        }
        &--txt {
            font-family: 'gm';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            text-align: center;
            color: #FFFFFF;
            @media all and (max-width: 680px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    &__map {
        div {
            @media all and (max-width: 1366px) {
                height: 400px !important;  
            }
            @media all and (max-width: 680px) {
                height: 375px !important;
            }
        }
    }
}