.docs__content {
    padding-top: 120px;
    padding-left: 80px;
    padding-right: 80px;
    @media all and (max-width: 960px) {
        padding-top: 60px;
        padding-left: 24px;
        padding-right: 24px;
    }
    &__h1 {
        font-family: 'gml', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 56px;
        text-transform: uppercase;
        color: #957B61;
        margin-bottom: 60px;
        @media all and (max-width: 680px) {
            margin-bottom: 40px;
            font-size: 24px;
            text-align: center;
            font-family: 'gm', serif;
            font-weight: normal;
        }
    }
    &__blocks {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: -30px;
        margin-right: -30px;
        @media all and (max-width: 1040px) {
            margin-left: 0;
            margin-right: 0;
        }
    }
    &__block {
        width: calc(20% - 30px);
        margin-bottom: 40px;
        display: block;
        text-decoration: none;
        text-align: center;
        @media all and (max-width: 1450px) {
            width: calc(33% - 30px);
        }
        @media all and (max-width: 1040px) {
            width: calc(50% - 10px);
        }
        @media all and (max-width: 680px) {
            width: 100%;
            text-align: center;
        }
        &--img {
            margin-bottom: 15px;
            @media all and (max-width: 680px) {
                max-width: 32px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        &--title {
            font-family: 'gmm', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            text-transform: uppercase;
            display: inline-block;
            color: #FFFFFF;
            background-image: linear-gradient(#fff, #fff);
            background-position: 0% 95%;
            background-repeat: no-repeat;
            background-size: 0% 2px;
            transition: background-size .3s;
            padding-bottom: 3px;
            @media all and (max-width: 680px) {
                font-size: 14px;
                line-height: 22px;
            }
        }
        &:hover {
            .docs__content__block--title {
                background-size: 100% 2px;
            }
        }
    }
}