.kladovie_plan {
    padding: 120px 25px;
    background: #fff;
    @media all and (max-width: 680px) {
        padding: 60px 25px;
    }
    &__in {
        max-width: 1150px;
        width: 100%;
        margin: 0 auto;
    }
    &__title {
        font-family: 'gml', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        color: #957B61;
        margin-bottom: 40px;
        @media all and (max-width: 680px) {
            font-size: 24px;
            font-family: 'gm', serif;
        }
    }
    &__btns {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 68px;
        @media all and (max-width: 680px) {
            margin-bottom: 28px;
        }
    }
    &__btn {
        text-decoration: none;
        display: inline-block;
        margin-right: 40px;
        font-family: 'gmm', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        color: #957B61;
        margin-bottom: 9px;
        background-image: linear-gradient(#957B61, #957B61);
        background-position: 0% 95%;
        background-repeat: no-repeat;
        background-size: 0% 1px;
        transition: background-size .3s;
        padding-bottom: 3px;
        &:hover {
            background-size: 100% 2px;
        }
        @media all and (max-width: 680px) {
            font-size: 14px;
        }
    }
}