.news_blocks {
    padding-top: 120px;
    padding-left: 80px;
    padding-right: 80px;
    @media all and (max-width: 960px) {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 60px;
    }
    &__h1 {
        margin-bottom: 80px;
        font-family: 'gml', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 56px;
        text-transform: uppercase;
        color: #957B61;
        @media all and (max-width: 680px) {
            font-size: 24px;
            margin-bottom: 40px;
            font-family: 'gm', serif;
            font-weight: normal;
        }
    }
    &__blocks {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__block {
        width: calc(50% - 95px);
        margin-bottom: 80px;
        @media all and (max-width: 1500px) {
            width: calc(50% - 30px);
        }
        @media all and (max-width: 1024px) {
            width: calc(50% - 10px);
            margin-bottom: 40px;
        }
        @media all and (max-width: 840px) {
            width: 100%;
        }
        &--img {
            position: relative;    
            padding-top: 51.4%;
            display: block;
            margin-bottom: 40px;
            font-size: 0;
            @media all and (max-width: 680px) {
                padding-top: 56%;
                margin-bottom: 20px;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }
        }
        &--title {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            margin-bottom: 17px;
            padding-bottom: 3px;
            text-transform: uppercase;
            color: #FFFFFF;
            text-decoration: none;
            display: inline-block;
            background-image: linear-gradient(#fff, #fff);
            background-position: 0% 95%;
            background-repeat: no-repeat;
            background-size: 0% 1px;
            transition: background-size .3s;
            &:hover {
                background-size: 100% 1px;
            }
            @media all and (max-width: 680px) {
                font-size: 14px;
                margin-bottom: 1px;
            }
        }
        &--text {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;
            @media all and (max-width: 680px) {
                font-size: 14px;
                line-height: 22px;
            }
        }
        &--info {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media all and (max-width: 680px) {
                margin-top: 26px;
            }
        }
        &--date {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: #FFFFFF;
            opacity: 0.6;
            @media all and (max-width: 680px) {
                font-size: 12px;
            }
        }
        &--more {
            font-family: 'gm', serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            text-transform: uppercase;
            color: #957B61;
            padding-bottom: 3px;
            background-image: linear-gradient(#957B61, #957B61);
            background-position: 0% 95%;
            background-repeat: no-repeat;
            background-size: 0% 1px;
            text-decoration: none;
            transition: background-size .3s;
            &:hover {
                background-size: 100% 1px;
            }
            @media all and (max-width: 680px) {
                font-size: 14px;
            }
        }
    }
}
.news_page .footer { 
    padding-top: 0;
    @media all and (max-width: 1024px) {
        padding-top: 80px;
    }
}