.kladovie_sl {
    height: 100vh;
    &__img {
        position: relative;
        min-height: 100vh;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    .slick-prev {
        left: 95px;
        z-index: 2;
        margin-top: 10px;
        background: none;
        &:before { 
            content: '';
            width: 14px;
            opacity: 1;
            display: block;    
            // box-shadow: inset 0px 0px 13px 4px #c4c4c4;
            height: 20px;
            background: url("data:image/svg+xml,%3Csvg width='15' height='22' viewBox='0 0 15 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.199219 10.84L1.18362 9.90928L11.0298 0.600098L14.199 0.600098L3.36821 10.8402L14.1988 21.08L11.0296 21.08L0.199219 10.84Z' fill='white'/%3E%3C/svg%3E%0A") 50% / 100% no-repeat;
        }
        @media all and (max-width: 680px) {
            display: none !important;
        }
    }
    .slick-next {
        right: 95px;
        margin-top: 10px;
        z-index: 2;
        background: none;
        &:before { 
            content: '';
            width: 14px;
            opacity: 1;
            // box-shadow: inset 0px 0px 13px 4px #c4c4c4;
            display: block;
            height: 20px;
            background: url("data:image/svg+xml,%3Csvg width='15' height='22' viewBox='0 0 15 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.8008 10.84L13.8164 9.90928L3.97016 0.600098L0.800956 0.600098L11.6318 10.8402L0.801161 21.08L3.97036 21.08L14.8008 10.84Z' fill='white'/%3E%3C/svg%3E%0A") 50% / 100% no-repeat;
        }
        @media all and (max-width: 680px) {
            display: none !important;
        }
    }
    .slick-dots {
        z-index: 2;
        bottom: 60px;
        @media all and (max-width: 680px) {
            bottom: 40px;
        }
        li {
            width: 48px;
            height: 4px;
            @media all and (max-width: 680px) {
                width: 32px;
                height: 2px;
            }
            button {
                width: 48px;
                height: 4px;
                padding: 0;
                display: block;
                margin-left: 8px;
                margin-right: 8px;
                background: #FFFFFF;
                opacity: 0.6;
                box-shadow: none !important; 
                @media all and (max-width: 680px) {
                    width: 32px;
                    height: 2px;
                    margin-left: 6px;
                    margin-right: 6px;
                }
            }
            &.slick-active {
                button {
                    opacity: 1;
                }
            }
        }
    }
}
.kladovie_page .footer {
    padding-top: 0;
}